// extracted by mini-css-extract-plugin
export var header = "home-module--header--25SFx";
export var resume = "home-module--resume--3EoWa";
export var img = "home-module--img--33UXD";
export var btn = "home-module--btn--2Jhvd";
export var exLink = "home-module--ex-Link--2hLyS";
export var grid1 = "home-module--grid1--1rZz2";
export var info = "home-module--info--2ycmL";
export var tech = "home-module--tech--2PLJd";
export var techGrid = "home-module--tech-grid--3moQ2";
export var icons = "home-module--icons--3Ex1-";
export var mails = "home-module--mails--2XvhY";
export var anchor1 = "home-module--anchor1--1m6Mv";